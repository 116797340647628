import { Link } from 'react-router-dom';
import {useSpring, animated} from 'react-spring';

function NotFound() {

  const fade = useSpring({from: {opacity:0}, to: {opacity:1}});

  return (
    <animated.h2 style={{...fade, margin:"30px"}}>
      This page doesn't exist. Check out <Link to="/albums">my favorite albums</Link>
    </animated.h2>
  )
}

export default NotFound;
import * as React from 'react';
import { Link } from 'react-router-dom';
import {useSpring, animated} from 'react-spring';
import { InstagramEmbed } from 'react-social-media-embed';

function Home() {

  const fade = useSpring({from: {opacity:0}, to: {opacity:1}});

  return (
    <animated.div style={{...fade, margin:"30px"}}>
      <h4>Hello there. I'm Mark and I like music. I like music so much I'm attempting to make some of my own. Hopefully it will be out soon.</h4>
      <div style={{ display: 'flex', justifyContent: 'center'}}>
        <InstagramEmbed style= {{position: 'relative', top:"-1px", borderRadius: '0.6rem'}} url="https://www.instagram.com/p/Caag4cMJeNF/" width={640} height={895}/>
      </div>
    </animated.div>
  )
}

export default Home;
import * as React from 'react';
import useMeasure from 'react-use-measure';
import {useSpring, animated} from 'react-spring';

import folder from '../images/folder.png';
import file from '../images/file.png';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

function PlaylistCreator(props) {


  const fade = useSpring({from: {opacity:0}, to: {opacity:1}});

  const [service, setService] = React.useState('');


  const handleChange = (event) => {
    setService(event.target.value);
  };

  function ServiceDropdown () {
    return (
      <Box sx={{ width: 180 }} >
        <FormControl fullWidth style={{color:"white"}}>
          <InputLabel >Streaming Service</InputLabel>
          <Select
            value={service}
            label="Streaming Service"
            onChange={handleChange}
           
          >
            <MenuItem value={'spotify'}>Spotify</MenuItem>
            <MenuItem value={'apple'}>Apple Music</MenuItem>
            <MenuItem value={'amazon'}>Amazon</MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
  }

  function BasicButton() {
    return (
      <DropdownButton id="dropdown-basic-button" title={service} variant="dark">
        <Dropdown.Item href="#/action-1">Spotify</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Apple Music</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Amazon</Dropdown.Item>
      </DropdownButton>
    );
  }


  return (
    <animated.div style={{...fade, margin:"30px"}}>
      <h4>Looking for a way to enjoy your favorite artist's music again? Having a hard time picking which album to listen to? Well, I have the solution for you! </h4>
      <h4>I created a custom playlist generator that goes through an artist's discography in album order, but with a random song from each album. </h4>
      <div className="row">
        <div className="col">
          <img src={folder} alt="Apple Music"></img> 
        </div>
        <div className="col">
          <img src={file} alt="Apple Music" width="1000px"></img> 
        </div>

      </div>
    </animated.div>
  );
};

export default PlaylistCreator;
import { useState } from "react";
import useMeasure from 'react-use-measure';
import {useSpring, animated} from 'react-spring'

import spotify from '../images/spotify logo.png';
import appleMusic from '../images/apple music logo.png';
import amazonMusic from '../images/amazon music logo.png';


function Album(props) {
  const [showAlbum, setShowAlbum] = useState(false);

  const [ref, { height }] = useMeasure();
  const openProps = useSpring({ height: showAlbum ? 475 : 385 });
  
  const [springs, api] = useSpring(() => ({
    from: { y:-15, opacity:0}, 
  }))

  const handleMessage = () => {
    handleSpringClick();
    setShowAlbum(!(showAlbum));
  };

  const handleSpringClick = () => {
    if (!showAlbum) {
      api.start({
        from: { y:-15, opacity:0}, 
        to: {
          y: 10, opacity: 1

        },
      })
    }

    else {
      api.start({ 
        to: {
          y: -45, opacity: 0
        },
      })
    }
  };


  return (
    <animated.div class="row" className="card" ml="30" style={{...openProps, width:360, margin:10}} >
      <div className="card-body">
        <animated.div >
          <h3 htmlFor="nameTextBox">{props.title}</h3>
          <img src={props.cover} onClick={handleMessage} alt="Out to Lunch!" draggable="false" style={{  maxWidth: '300px', width:"-webkit-fill-available"}} />
          <animated.h6 style={{...springs}} ref={ref}> 
            <div class="row">
              {props.description} 
            </div>
            <div class="row" style={{display:"inline"}} height="50px"> 
              <a href={"https://open.spotify.com/album/" + props.spotify} target="_blank" hidden = {(props.service == 'spotify' || props.service == '') ? false : true} > 
                <img src={spotify} alt="Spotify" width="75px"></img> 
              </a>
              <a href={"https://music.apple.com/us/album/" + props.apple} target="_blank" hidden = {(props.service == 'apple' || props.service == '') ? false : true}> 
                <img src={appleMusic} alt="Apple Music" width="90px"></img> 
              </a>
              <a href={"https://music.amazon.com/albums/" + props.amazon} target="_blank" hidden = {(props.service == 'amazon' || props.service == '') ? false : true}> 
                <img src={amazonMusic} alt="Amazon Music" width="80px"></img> 
              </a>
            </div>
          </animated.h6>
        </animated.div>
      </div>
    </animated.div>
  );
};

export default Album;
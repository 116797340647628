import { React} from 'react'
import Home from './components/Home';
import Albums from './components/Albums';
import PlaylistCreator from './components/PlaylistCreator';
import NotFound from './components/NotFound';
import {useSpring, animated} from 'react-spring';


import { 
  BrowserRouter as Router,
  Link,
  Switch,
  Route,
  } from 'react-router-dom';




function App() {

  const fade = useSpring({from: {opacity:0}, to: {opacity:1}});

  return (
    <div className="App">
        <Router>
          <ul>
            <ul>
              <Link to="/">Home</Link>
            </ul>
            <ul>
              <Link to="/albums">Albums You Should Listen To</Link>
            </ul>
            <ul>
              <Link to="/playlist">Custom Playlist Creator</Link>
            </ul>
          </ul>
        
          <Switch>

            <Route exact path="/">
              <Home/>
            </Route>

            <Route path="/albums">
              <Albums/>          
            </Route>

            <Route path="/playlist">
              <PlaylistCreator/>          
            </Route>

            <Route path="*">
              <NotFound />
            </Route>

          </Switch> 
        </Router>   
    </div>
  );
}

export default App;

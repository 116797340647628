import * as React from 'react';
import useMeasure from 'react-use-measure';
import {useSpring, animated} from 'react-spring';

import outToLunch from '../images/out to lunch.png';
import absolutelyFree from '../images/absolutely free.png';
import troutMaskReplica from '../images/trout mask replica.png';
import sheerHeartAttack from '../images/sheer heart attack.png';
import outOfDarkness from '../images/background.png';
import scienceFiction from '../images/science fiction.png';
import flyByNight from '../images/fly by night.png';
import hellfire from '../images/hellfire.png';
import oneSizeFitsAll from '../images/one size.png';
import revolver from '../images/revolver.png';

import Album from "./Album";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

function Albums(props) {


  const fade = useSpring({from: {opacity:0}, to: {opacity:1}});

  const [service, setService] = React.useState('');

  const handleChange = (event) => {
    setService(event.target.value);
  };

  function ServiceDropdown () {
    return (
      <Box sx={{ width: 180 }} >
        <FormControl fullWidth >
          <InputLabel >Streaming Service</InputLabel>
          <Select
            value={service}
            label="Streaming Service"
            onChange={handleChange}
          >
            <MenuItem value={'spotify'}>Spotify</MenuItem>
            <MenuItem value={'apple'}>Apple Music</MenuItem>
            <MenuItem value={'amazon'}>Amazon</MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
  }
  
  function OutToLunch () {
    return (
      <Album title={"Out to Lunch!"} cover={outToLunch} spotify={"3PIVqZzL1PnrxFZDzuT1aX"} apple={"out-to-lunch/1455686798"} amazon={"B000SZEXN2"} service={service}
      description={"My all time favorite musical work. Like life, it's chaotic and unintuitive, frustrating and strange, but ultimately so beautiful and worth enjoying."}/>
    )
  }

  function TroutMaskReplica () {
    return (
      <Album title={"Trout Mask Replica"} cover={troutMaskReplica} spotify={"4dgAnIHFpnFdSBqpRZheHq"} apple={"trout-mask-replica/1579587935"} amazon={"B09BTV2QLS"} 
      service={service}
      description={"It may come off as grating and nonsensical, and you might think I'm trolling you. Believe me when I tell you it makes sense after seven listens."}/>
    )
  }

  function AbsolutelyFree () {
    return (
      <Album title={"Absolutely Free"} cover={absolutelyFree} spotify={"5quWwqogyMjzEXWgCVyvRP"} apple={"absolutely-free/1442926805"} amazon={"B00AAAK8AW"}
      service={service}
      description={"A sharp satire of American culture that happens to include Igor Stravisnky quotes and tales of anthromorphic vegetables. Only in America!"}/>
    )
  }

  function SheerHeartAttack () {
    return (
      <Album title={"Sheer Heart Attack"} cover={sheerHeartAttack} spotify={"5CooX2xg5YibepSfjbRFNT"} apple={"sheer-heart-attack/1440657490"} amazon={"B004ZKL8VQ"}
      service={service} 
      description={"My favorite album from the greatest band ever. Sheer Heart Attack captures the heights and depths of life better than other work of art."} />
    )
  }

  function FlyByNight () {
    return (
      <Album title={"Fly By Night"} cover={flyByNight} spotify={"3ZtICWkqezf0bBTUwY1Khe"} apple={"fly-by-night-remastered/1440724415"} amazon={"B000W1XFEA"} 
      service={service} 
      description={"Really great rock and roll from three incredible musicians. This was a huge influence on me as a guitarist and as an album is sorely underrated."}/>
    )
  }

  function OneSizeFitsAll () {
    return (
      <Album title={"One Size Fits All"} cover={oneSizeFitsAll} spotify={"4mp2SC1Wq9jZJINGPAKv0V"} apple={"one-size-fits-all/1440928474"} amazon={"B00AEFLROO"}
      service={service} 
      description={"From complex time signatures to odd melodies, this incredible record will give your head a spin and your heart a tug. On Ruth!"}/>
    )
  }

  function Revolver () {
    return (
      <Album title={"Revolver"} cover={revolver} spotify={"3PRoXYsngSwjEQWR5PsHWR"} apple={"revolver/1441164670"} amazon={"B07FSW1QNS"} service={service}
      description={"Revolver is beginning of the Beatles' transition from live sensations to studio masters. Abbey Road is great too, but this takes the cake for me."} />
    )
  }

  function Hellfire () {
    return (
      <Album title={"Hellfire"} cover={hellfire} spotify={"68z6MWYYNmvTcru1QMcYId"} apple={"hellfire/1613170781"} amazon={"B09Y5SYF3T"} service={service}
      description={"The finest modern rock album I've heard. The musicianship and songwriting are second to none. This is the music I wish I could make."}/>
    )
  }

  return (
    <animated.div style={{...fade, margin:"30px"}}>
      <h4>These are some of my favorite albums. Click on them to find out why.</h4>
      <div class="row" style={{display:"flex", padding:30}}>
        <OutToLunch />
        <TroutMaskReplica />
        <AbsolutelyFree />
        <SheerHeartAttack />
        <FlyByNight />
        <OneSizeFitsAll />
        <Revolver />
        <Hellfire />
      </div>
    </animated.div>
  );
};

export default Albums;